import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AddBoxPopupContext } from "../../App"
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddBox } from '../../services/auth/auth.services';

const AddBoxPopup = ({ selectedGroup, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [handleAddBoxPopup, setHandleAddBoxPopup] = useContext(AddBoxPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);

    const navigate = useNavigate();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const returnMatchedGroupIndex = () => {
        for (let i = 0; i < mergeGroupAndBoxes.length; i++) {
            if (mergeGroupAndBoxes[i].id === selectedGroup.id) {
                return i
            }
        }
    }

    const onSubmit = async (data) => {
        const id = selectedGroup.id
        const { lessonTitle } = data
        setIsDisabled(true)

        try {
            await AddBox(id, lessonTitle, 1).then((res) => {
                const newlyAddedData = res.data.data
                const index = returnMatchedGroupIndex()
                newlyAddedData.box_resources = []
                newlyAddedData.resources = []
                mergeGroupAndBoxes[index].boxes.push(newlyAddedData)
                setMergeGroupAndBoxes(mergeGroupAndBoxes)
                reset({ lessonTitle: ""})
                // success(res.data.message)
                setHandleAddBoxPopup(false)
                setIsDisabled(false)
            })
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>
            <Modal
                className='add-box-modal'
                show={handleAddBoxPopup}
                onHide={() => {
                    setHandleAddBoxPopup(false)
                    reset({lessonTitle:""})
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleAddBoxPopup(false)
                            reset({lessonTitle:""})
                        }} 
                        className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Add Box</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                {/* <div className='text-center'>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the look.
                                    </p>
                                </div> */}

                                <div className=''>
                                    <Form.Label>Box Title</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.LESSON_TITLE}
                                            autoComplete="off"
                                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="lessonTitle"
                                            type="text"
                                            autoFocus={true}
                                            placeholder="Enter box title"
                                            {...register("lessonTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.LESSON_TITLE,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.lessonTitle && <small className='text-red'>{errors.lessonTitle.message}</small>}

                                </div>

                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Add</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddBoxPopup