import React, { useState, useEffect } from 'react'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const PdfViewer = ({ data }) => {
    const [docs, setDocs] = useState([])

    useEffect(() => {
        docs.push({ uri: data.file_url })
    }, [])


    return (
        <div className='general-file-viewer'>
            <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                config={{
                    header: {
                        disableHeader: true,
                        disableFileName: false,
                        retainURLParams: false
                    }
                }}
            />
        </div>
    )
}

export default PdfViewer