import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FaHistory, FaTimes } from "react-icons/fa"
import { MdDashboardCustomize, MdEmail } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { SubAdminProfilePopupContext, DeleteAccountPopupContext, LogoutPopupContext, UserDataContext } from "../../App"
import Assets from '../../constants/images'
import { LockIcon, LogoutIcon, PrivacyIcon, SettingIcon, SingleUserIcon, TermsAndConditionIcon, UserIcon } from '../../constants/svgs'
import DeleteAccountPopup from './DeleteAccountPopup'
import LogoutPopup from './LogoutPopup'

const SubAdminProfilePopup = () => {
    const [userData, setUserData] = useContext(UserDataContext)
    const [handleSubAdminProfilePopup, setHandleSubAdminProfilePopup] = useContext(SubAdminProfilePopupContext);
    const [handleLogoutPopup, setHandleLogoutPopup] = useContext(LogoutPopupContext);

    return (
        <div>
            <LogoutPopup btnText1="No" btnText2="Yes" heading="Alert" title="Are you sure you want to logout ?" />

            <Modal
                className='profile-modal'
                show={handleSubAdminProfilePopup}
                onHide={() => setHandleSubAdminProfilePopup(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleSubAdminProfilePopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='profile-container p-2'>
                        <div className='text-center border-bottom pb-4 mb-4'>
                            <h4 className="p-0 m-0">My Account</h4>
                        </div>

                        <div className='img-container text-center border-bottom pb-3 '>
                            <div className="img-holder mx-auto text-center">
                                <img onError={(e) => e.target.src = Assets.ProfilePlaceHolder} src={userData?.details?.image_url ? userData.details.image_url : Assets.AvatarLarge} alt="Avatar" />
                            </div>
                            <h6 className='mt-3'>{userData?.name}</h6>
                        </div>

                        <div className='link-container'>
                            <ul>
                                <li className='border-bottom py-3 d-block d-md-none'>
                                    <div className='d-flex align-items-center'>
                                        <span className='icon'><MdDashboardCustomize /></span>
                                        <NavLink className="ms-2" to="/sub-admin/manage-group">Manage Groups</NavLink>
                                    </div>
                                </li>

                                <li className='border-bottom py-3 d-block d-md-none'>
                                    <div className='d-flex align-items-center'>
                                        <span className='icon'><MdEmail /></span>
                                        <NavLink className="ms-2" to="/sub-admin/email-setup">E-mail Setup</NavLink>
                                    </div>
                                </li>

                                <li className='border-bottom py-3 d-block d-md-none'>
                                    <div className='d-flex align-items-center'>
                                        <span className='icon'><FaHistory /></span>
                                        <NavLink className="ms-2" to="/sub-admin/client-history">Client History</NavLink>
                                    </div>
                                </li>

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><SingleUserIcon /></span>
                                        <NavLink className="ms-2" to="/sub-admin/profile">Profile</NavLink>
                                    </div>
                                </li>

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><SettingIcon /></span>
                                        <NavLink className="ms-2" to="/sub-admin/settings/change-password">Advanced Settings</NavLink>
                                    </div>
                                </li>

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><LogoutIcon /></span>
                                        <NavLink className="ms-2" to="#" onClick={() => setHandleLogoutPopup(true)}>Logout</NavLink>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SubAdminProfilePopup