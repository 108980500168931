import React, { useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'


const SettingPage = ({ children1, children2 }) => {
    return (
        <div className="setting-page">
            <Row>
                <Col sm={12} md={5} lg={4} xl={3} className="mb-4">
                    {children1}
                </Col>

                <Col sm={12} md={7} lg={8} xl={9}>
                    <Row>
                        {children2}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default SettingPage
