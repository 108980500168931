import React, { useContext, useEffect, useState } from 'react'
import MyProfileCard from '../components/home/MyProfileCard'
import EditProfileForm from '../components/home/EditProfileForm';
import { Col, Row } from 'react-bootstrap';
import { GetCurrentUser, GetProfile, SetAuthUserLocalStorage } from '../services/auth/auth.services';
import { success, error } from "../constants/msg";
import { ToastContainer } from "react-toastify";
import { UserDataContext } from '../App';

const MyProfilePage = ({ margin }) => {
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useContext(UserDataContext)

    const showPanel = () => {
        setShow(true)
    }

    const hidePanel = () => {
        setShow(false)
    }

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const res = await GetCurrentUser()
                setUserData(res.data.data)
                SetAuthUserLocalStorage(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }
        getCurrentUser()

    }, [])


    return (
        <div className={`my-profile-page ${margin && "mt-4"}`}>
            <ToastContainer />
            <Row>
                <Col sm={7} md={6} lg={4} xl={3} className="mb-4">
                    <div className="left">
                        <MyProfileCard user={userData} showPanel={showPanel} />
                    </div>
                </Col>

                {
                    show &&
                    <Col sm={12} md={6} lg={{ span: 7, offset: 1 }} xl={7} className="mb-4">
                        <div className="right">
                            <EditProfileForm user={userData} hidePanel={hidePanel} />
                        </div>
                    </Col>
                }
            </Row>
        </div>
    )
}

export default MyProfilePage