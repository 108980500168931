import React, { useState, useContext, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { RemoveBoxPopupContext, RemoveDeleteGroupPopupContext } from "../../App"
import { useNavigate } from 'react-router-dom'
import { Logout, EmptyLocalStorage, DeleteResource, DeleteBox } from "../../services/auth/auth.services"
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";

const RemoveDeletedBoxPopup = ({ selectedBox, setSelectedBox, setshowSelectedResource, deletedGroupData, setDeletedGroupData }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [handleRemoveDeletedGroupPopup, setHandleRemoveDeletedGroupPopup] = useContext(RemoveDeleteGroupPopupContext);
    const navigate = useNavigate()

    const deleteBox = async () => {
        const { index, id } = selectedBox
        setIsDisabled(true)

        try {
            let temp = [...deletedGroupData]
            await DeleteBox(id).then((res) => {
                temp.splice(index, 1)
                setDeletedGroupData(temp)
                success(res.data.message)

                setshowSelectedResource(null)
                setHandleRemoveDeletedGroupPopup(false)
                setIsDisabled(false)
                setSelectedBox({
                    id: "",
                    index: "",
                    name: "",
                    group_index: ""
                })
            })

        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(() => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>
            <Modal
                className='logout-modal'
                show={handleRemoveDeletedGroupPopup}
                onHide={() => setHandleRemoveDeletedGroupPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleRemoveDeletedGroupPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Box</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Deleting this box will also remove all resources contained inside
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button disabled={isDisabled} onClick={() => deleteBox()} className="btn-common me-4">Delete</Button>
                                <Button onClick={() => setHandleRemoveDeletedGroupPopup(false)} className="btn-common no-bg">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RemoveDeletedBoxPopup