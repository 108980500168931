import React, { useContext } from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import SubAdminHeader from '../../../components/home/SubAdminHeader'
import { CalendarIcon } from '../../../constants/svgs'
import { CalendarPopupContext } from '../../../App';
import CalendarPopup from '../../../components/popup/CalendarPopup';
import { NavLink } from 'react-router-dom';

const SubAdminMainLayout = ({ children, homeLink, breadcrumbLink1, breadcrumbLink2, breadcrumbLink3, show }) => {
    const [handleCalendarPopup, setHandleCalendarPopup] = useContext(CalendarPopupContext);

    return (
        <div className='main-layout sub-admin-main-layout p-sm-4 p-0 py-3 pb-5'>
            <Container className="h-100">
                <Row>
                    <Col xs={12}>
                        <SubAdminHeader />
                    </Col>

                    <Col xs={12} className="my-3">
                        <div className="d-flex align-items-sm-center justify-content-between flex-column flex-sm-row">
                            <Breadcrumb>
                                <Breadcrumb.Item><NavLink to={`/${homeLink}`}>Home</NavLink></Breadcrumb.Item>
                                {
                                    breadcrumbLink2 !== "" ?
                                        <>
                                            <Breadcrumb.Item><NavLink to="/sub-admin/settings/change-password">{breadcrumbLink1}</NavLink></Breadcrumb.Item>
                                            <Breadcrumb.Item active>{breadcrumbLink2}</Breadcrumb.Item>
                                        </>
                                        :
                                        <>
                                            {
                                                breadcrumbLink1 !== "" &&
                                                <Breadcrumb.Item active>{breadcrumbLink1}</Breadcrumb.Item>
                                            }
                                        </>
                                }
                                {
                                    breadcrumbLink3 &&
                                    <>
                                        <Breadcrumb.Item active>{breadcrumbLink3}</Breadcrumb.Item>
                                    </>
                                }
                            </Breadcrumb>
                        </div>
                    </Col>
                </Row>

                {children}
            </Container>
        </div>
    )
}

export default SubAdminMainLayout