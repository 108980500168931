
import axios from "axios";
import { constant, apiUrl } from "../../utils/constants"
import { GetTokenLocalStorage } from "./auth.services";

export const GetPages = async (id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.pages}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return response;
}