import React from 'react'
import { NavLink } from 'react-router-dom'

const SideNavigation = () => {
    return (
        <div className="nav-container p-4">
            <NavLink className="w-100 d-block mb-3" to="/sub-admin/settings/change-password">Change Password</NavLink>
            <NavLink className="w-100 d-block mb-3" to="/sub-admin/settings/subscription-upgrade">Manage Subscription</NavLink>
            {/* <NavLink className="w-100 d-block mb-3" to="/sub-admin/settings/terms-and-conditions">Terms & Conditions</NavLink> */}
            {/* <NavLink className="w-100 d-block mb-3" to="/sub-admin/settings/privacy-policy">Privacy Policy</NavLink> */}
            <a className="w-100 d-block mb-3" target="_blank" href="https://www.grahamity.com/terms/">Terms & Conditions</a>
            <a className="w-100 d-block mb-3" target="_blank" href="https://www.grahamity.com/privacy/">Privacy Policy</a>
        </div>
    )
}

export default SideNavigation