import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { NotificationPopupContext } from "../../App"
import { GetNotification } from '../../services/auth/auth.services'
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";

const NotificationPopup = () => {
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [notifications, setNotifications] = useState([])
    let unread_count = 0

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const res = await GetNotification();
                setNotifications(res.data.data)
            }
            catch (e) {
                error(e.response.data.errors[0].message)
            }
        }
        getNotifications()
    }, [])

    const returnUnreadMessage = () => {
        notifications?.filter((data)=>{
            if(data.read_at === null){
                unread_count+=1
            }
        })
        return unread_count
    }

    return (
        <div>
            <ToastContainer/>
            <Modal
                className='notification-modal'
                show={handleNotificationPopup}
                onHide={() => setHandleNotificationPopup(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Notifications <span>({useMemo(()=>returnUnreadMessage(), [notifications])})</span></h6>
                        <div onClick={() => setHandleNotificationPopup(false)} className="btns-time d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <div className='notification-container'>
                        {
                            notifications.length > 0 &&
                            notifications.map((v, i) => (
                                <NotificationCard key={i} data={v} bg="bg-dark-green" />
                            ))
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NotificationPopup