import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AddGroupPopupContext } from "../../App"
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddGroup } from '../../services/auth/auth.services';

const AddGroupPopup = ({ session, isStateUpdate, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useContext(AddGroupPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);

    const navigate = useNavigate();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const id = session.id
        const { groupTitle } = data
        if (id) {
            setIsDisabled(true)
            try {
                const res = await AddGroup(id, groupTitle, 1)
                reset({ groupTitle: "" })
                success(res.data.message)
                if (isStateUpdate === true) {
                    const newlyAddedGroup = res.data.data;
                    newlyAddedGroup.boxes = []
                    setMergeGroupAndBoxes([
                        ...mergeGroupAndBoxes,
                        newlyAddedGroup
                    ])
                }
                setHandleAddGroupPopup(false)

                setTimeout(() => {
                    navigate("/sub-admin/manage-group", {
                        state: {
                            session: session, data: {
                                group_id: res.data.data.id,
                                box_id: ""
                            }
                        }
                    })
                    setIsDisabled(false)
                }, 1500)
            }
            catch (e) {
                error(e.response.data.message)
                setTimeout(async () => {
                    setIsDisabled(false)
                }, 1500)
            }
        }
        else {
            error("No active session available")
        }
    }

    return (
        <div>
            <Modal
                className='add-group-modal'
                show={handleAddGroupPopup}
                onHide={() => {
                    setHandleAddGroupPopup(false)
                    reset({ groupTitle: "" })
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleAddGroupPopup(false)
                            reset({ groupTitle: "" })
                        }}
                            className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Add Group</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                {/* <div className='text-center'>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the look.
                                    </p>
                                </div> */}

                                <div className=''>
                                    <Form.Label>Group Title</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.GROUP_TITLE}
                                            autoComplete="off"
                                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="groupTitle"
                                            type="text"
                                            placeholder="Enter group title"
                                            autoFocus={true}
                                            {...register("groupTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.GROUP_TITLE,
                                                        message: VALIDATIONS_TEXT.GROUP_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.GROUP_TITLE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.groupTitle && <small className='text-red'>{errors.groupTitle.message}</small>}
                                </div>

                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Add</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddGroupPopup