import React, { useContext, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AudioPopupContext } from "../../App"
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Assets from '../../constants/images';
import Player from '../home/Player';

const AudioPopup = ({data}) => {
    const [handleAudioPopup, setHandleAudioPopup] = useContext(AudioPopupContext);

    return (
        <div>
            <ToastContainer />
            <Modal
                className='player-modal'
                show={handleAudioPopup}
                onHide={()=>setHandleAudioPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleAudioPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='p-1 bg-white br-20'>
                        <Player data={data} isAudioPlayer={true} show1="d-none" show2="d-block" paddingY="py-5" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AudioPopup