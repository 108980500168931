import React, { Component, createRef, useEffect, useRef, useState } from 'react'
import { findDOMNode } from 'react-dom'
import { hot } from 'react-hot-loader'
import screenfull from 'screenfull'
import ReactPlayer from 'react-player'
import video from "../../assets/files/video.mp4"
import Assets from '../../constants/images'
import { BiSkipPrevious, BiPause, BiPlay, BiSkipNext, BiFullscreen } from "react-icons/bi"
import { FiRepeat } from "react-icons/fi"
import { TbRepeatOff } from "react-icons/tb"
import { VscUnmute, VscMute } from "react-icons/vsc"
import { MusicIcon, MusicIconSmall } from '../../constants/svgs'

const Player = (props) => {
  const playerRef = useRef()
  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  })

  const SEPARATOR = ' · '
  const currentProgress = state.played * 100;
  const trackProgressStyling = `linear-gradient(to right, #FF574C ${currentProgress}%, #5E5A5A ${currentProgress}%)`;

  useEffect(() => {
    setState({
      ...state,
      playing: false,
    })
  }, [props.click])

  function format(seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }

  function pad(string) {
    return ('0' + string).slice(-2)
  }

  const handlePlayPause = () => {
    let { playing } = state
    setState({
      ...state,
      playing: !playing
    })
  }

  const handleOnPlaybackRateChange = (speed) => {
    setState({
      ...state,
      playbackRate: parseFloat(speed)
    })
  }

  const handlePlay = () => {
    setState({
      ...state,
      playing: true
    })
  }

  const handleEnablePIP = () => {
    setState({
      ...state,
      pip: true
    })
  }

  const handleDisablePIP = () => {
    setState({
      ...state,
      pip: false
    })
  }

  const handlePause = () => {
    setState({
      ...state,
      playing: false
    })
  }

  const handleSeekMouseDown = e => {
    setState({
      ...state,
      seeking: true
    })
  }

  const handleSeekChange = e => {
    setState({
      ...state,
      played: parseFloat(e.target.value)
    });
  }

  const handleSeekMouseUp = e => {
    setState({
      ...state,
      seeking: false
    })
    playerRef.current.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = e => {
    let { seeking } = state
    if (!seeking) {
      setState({
        ...state,
        played: e.played
      })
    }
  }

  const handleEnded = () => {
    let { loop } = state
    setState({
      ...state,
      playing: loop
    })
  }

  const handleDuration = (duration) => {
    setState({
      ...state,
      duration: duration
    })
  }

  const handleClickFullscreen = () => {
    setState({
      ...state,
      playing: true
    })
    screenfull.request(findDOMNode(playerRef.current))
  }

  return (
    <div className='custom-player'>
      <div className={`holder ${props.paddingY} p-4`}>
        <div className={props.isAudioPlayer ? "d-none" : "d-block mb-4"}>
          <ReactPlayer
            ref={playerRef}
            className='react-player'
            width='100%'
            height='100%'
            url={props.data?.file_url}
            pip={state.pip}
            playing={state.playing}
            controls={state.controls}
            light={state.light}
            loop={state.loop}
            playbackRate={state.playbackRate}
            volume={state.volume}
            muted={state.muted}
            onPlay={handlePlay}
            onEnablePIP={handleEnablePIP}
            onDisablePIP={handleDisablePIP}
            onPause={handlePause}
            onPlaybackRateChange={handleOnPlaybackRateChange}
            onEnded={handleEnded}
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
        </div>

        {
          props.isAudioPlayer ?
            <>
              <div className={`description-container-normal d-flex align-items-center justify-content-center text-center flex-column mb-5 ${props.show1}`}>
                <div className="img-container">
                  <MusicIconSmall />
                </div>
                <div className=''>
                  <h6 className='m-0 p-0'>{props.data.title}</h6>
                </div>
              </div>

              <div className={`description-container-popup d-flex align-items-center mb-5 ${props.show2}`}>
                <div className='icon-container px-2'>
                  <MusicIconSmall />
                </div>

                <div className='ms-3'>
                  {/* <h6>Summy Music</h6> */}
                  <h6 className='m-0 p-0'>{props.data.title}</h6>
                </div>
              </div>
            </>
            :
            <></>
        }

        <div>
          <div className="progress-container">
            <input
              className='w-100'
              type='range'
              min={0}
              max={0.999999}
              step='any'
              value={state.played}
              id='rangeid'
              style={{ background: trackProgressStyling }}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
            />

            <div className='w-100 d-flex mb-2 align-items-center justify-content-between'>
              <span>{format(state.duration * state.played)}</span>
              <span>{format(state.duration)}</span>
            </div>
          </div>

          {/* <div className={`d-flex align-items-center justify-content-center`}>
            <div className='btn-repeat me-4'><TbRepeatOff /></div>
            <div className='btn-prev me-4'><BiSkipPrevious /></div>
            <div className='btn-play me-4' onClick={() => handlePlayPause()}>{state.playing ? <BiPause /> : <BiPlay />}</div>
            <div className='btn-next me-4'><BiSkipNext /></div>
            <div className='btn-repeat'><FiRepeat /></div>
            {
              props.isAudioPlayer===false && 
              <div className='btn-repeat ms-4' onClick={()=>handleClickFullscreen()}><BiFullscreen /></div>
            }
          </div> */}

          <div className={`d-flex align-items-center justify-content-between`}>
            <div className='btn-repeat'>
              {
                state.muted ?
                  <span onClick={() => setState({ ...state, muted: !state.muted })}><VscMute /></span>
                  :
                  <span onClick={() => setState({ ...state, muted: !state.muted })}><VscUnmute /></span>
              }
            </div>
            <div className='btn-play' onClick={() => handlePlayPause()}>{state.playing ? <BiPause /> : <BiPlay />}</div>
            <div className={`btn-repeat ${props.isAudioPlayer ? "invisible" : "visible "}`} onClick={() => handleClickFullscreen()}><BiFullscreen /></div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default hot(module)(Player)


