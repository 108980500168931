import React, { useContext } from 'react'
import { BsFillBellFill } from "react-icons/bs"
import Assets from '../../constants/images'
import { Breadcrumb } from "react-bootstrap"
import { NotificationPopupContext, ProfilePopupContext, UserDataContext } from "../../App"
import NotificationPopup from '../popup/NotificationPopup'
import ProfilePopup from '../popup/ProfilePopup'
import { NavLink } from 'react-router-dom'
import { roles } from "../../utils/constants"

const Header = ({ showBreadcrumb, heading, homeLink, breadcrumbLink }) => {
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [handleProfilePopupContextPopup, setHandleProfilePopupContextPopup] = useContext(ProfilePopupContext);
    const [userData, setUserData] = useContext(UserDataContext);

    return (
        <header className='header pb-4'>
            <NotificationPopup />
            <ProfilePopup />

            <div className='d-flex align-items-center justify-content-between'>
                <div className={showBreadcrumb ? "d-none d-md-block " : "d-none d-md-block"}>
                    {
                        showBreadcrumb &&
                        <Breadcrumb>
                            <Breadcrumb.Item><NavLink to={`/${homeLink}`}>Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item active>{breadcrumbLink}</Breadcrumb.Item>
                        </Breadcrumb>
                    }
                    <h5 className='p-0 m-0 mt-1'>{heading}</h5>
                </div>

                <div className="me-4">
                    <NavLink to={"/current-session"}>
                        <img id="logo" className='w-100' src={Assets.Logo} alt="Logo" />
                    </NavLink>
                </div>

                <div className='d-flex align-items-center'>
                    {/* <div className='circle-div me-3 me-md-4' onClick={() => setHandleNotificationPopup(true)}>
                        <div className="notify"></div>
                        <BsFillBellFill />
                    </div> */}

                    <div className='circle-div' onClick={() => setHandleProfilePopupContextPopup(true)}>
                        <img onError={(e) => e.target.src = Assets.ProfilePlaceHolder} src={userData?.details?.image_url ? userData.details.image_url : Assets.AvatarLarge} alt="Avatar" />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header