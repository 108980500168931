const Assets = {
    BackgroundImage1: require("../assets/img/bg-1.png"),
    BackgroundImage2: require("../assets/img/bg-2.png"),
    BackgroundImage3: require("../assets/img/bg-3.png"),
    Logo: require("../assets/img/logo.png"),
    LogoLarge: require("../assets/img/LogoLarge.png"),
    Avatar: require("../assets/img/avatar.jpg"),
    AvatarLarge: require("../assets/img/avatar-large.png"),
    Art1: require("../assets/img/art1.png"),
    Art2: require("../assets/img/art2.png"),
    Singer: require("../assets/img/singer.png"),
    SingerLarge: require("../assets/img/singer-large.png"),
    BobMarley: require("../assets/img/bob-marley.png"),
    Men: require("../assets/img/men.png"),
    MenCircle: require("../assets/img/men-circle.png"),
    MusicBar: require("../assets/img/music-bar.png"),
    MusicBarColored: require("../assets/img/music-bar-colored.png"),
    Pdf: require("../assets/files/mypdf.pdf"),
    ImagePlaceHolder: require("../assets/img/NoImageFound.png"),
    ProfilePlaceHolder: require("../assets/img/profile-placeholder.png"),
}

export default Assets