import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Header from '../../components/home/Header'

const FullLayout = ({ children, heading, showBreadcrumb }) => {
    return (
        <div className='full-layout p-sm-4 p-0 py-3 pb-5'>
            <Container >
                <Row>
                    <Col xs={12}>
                        <Header heading={heading} showBreadcrumb={showBreadcrumb} />
                    </Col>

                    <Col xs={12} className="d-block d-md-none mt-4">
                        <h4>{heading}</h4>
                    </Col>

                    <Col xs={12} className="mt-3 mt-md-4">
                        {children}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FullLayout