import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Header from '../../components/home/Header'

const MainLayout = ({ children, heading, showBreadcrumb, homeLink, breadcrumbLink }) => {
    return (
        <div className='main-layout p-sm-4 p-0 py-3 pb-5'>
            <Container className="h-100">
                <Row>
                    <Col xs={12}>
                        <Header heading={heading} homeLink={homeLink} showBreadcrumb={showBreadcrumb} breadcrumbLink={breadcrumbLink} show={true} />
                    </Col>
                </Row>

                <Row className="d-block d-md-none mt-4">
                    <Col xs={12}>
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item><NavLink to={`/${homeLink}`}>Home</NavLink></Breadcrumb.Item>
                                <Breadcrumb.Item active>{breadcrumbLink}</Breadcrumb.Item>
                            </Breadcrumb>
                            <h5 className='p-0 m-0 mt-2'>{heading}</h5>
                        </div>
                    </Col>
                </Row>

                {children}
            </Container>
        </div>
    )
}

export default MainLayout