import React from 'react'
import { useLocation } from 'react-router-dom'
import { FileIconXLarge, PdfIconLarge } from '../../constants/svgs'
import { MdDownloadForOffline } from "react-icons/md"
import { Button } from "react-bootstrap"

const PdfFileCard = ({ data, downloadFile, click, setClick }) => {

    return (
        <div onClick={()=>setClick(!click)}  className='my-card file-card pb-3 p-2'>
            {/* <Button onClick={()=>downloadFile(data?.file_url)} className='download-btn'><MdDownloadForOffline /></Button> */}
            <div className='bg-div bg-light-grey py-4'>
                <div className='file-icon-container bg-white'>
                    <FileIconXLarge />
                </div>
            </div>
            <span className='d-inline-block mt-2'>{data?.title}</span>
        </div>
    )
}

export default PdfFileCard