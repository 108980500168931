import React, { useState, useEffect, useContext } from 'react'
import { Route, Navigate, Outlet } from "react-router";
import { GetAuthUserLocalStorage, GetTokenLocalStorage } from "./services/auth/auth.services"

const ProtectedRoute = ({requiredRole}) => {
    const token = GetTokenLocalStorage()
    const user = GetAuthUserLocalStorage()

    return (
        (token && (requiredRole[0] === user.role_id || requiredRole[1] === user.role_id || requiredRole[2] === user.role_id )) ? <Outlet /> : <Navigate to="/signin" />
    )
}

export default ProtectedRoute