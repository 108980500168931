import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Assets from "../constants/images"
import { Logo } from '../constants/svgs'

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div className="home-page">
            <Container className="h-100">
                <Row className="justify-content-center h-100">
                    <Col xs={10} sm={4} className="h-100">
                        <div className="content-container text-center d-flex flex-column align-items-center justify-content-center h-100">
                            <img className='w-100' src={Assets.LogoLarge} alt="Logo" />
                            <Button onClick={() => navigate("/signin")} className="w-100 mt-5">Proceed</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomePage