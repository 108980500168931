import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { EditBoxPopupContext, EditGroupPopupContext } from "../../App"
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddGroup, EditBox, EditGroup } from '../../services/auth/auth.services';

const EditBoxPopup = ({ selectedBox, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [handleEditBoxPopup, setHandleEditBoxPopup] = useContext(EditBoxPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);

    const navigate = useNavigate();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        reset({
            lessonTitle: selectedBox.name
        })
    }, [selectedBox])

    const onSubmit = async (data) => {
        const { lessonTitle } = data
        try {
            const res = await EditBox(selectedBox.id, lessonTitle)
            mergeGroupAndBoxes[selectedBox.group_index].boxes[selectedBox.index].name = lessonTitle
            setMergeGroupAndBoxes(mergeGroupAndBoxes)
            success(res.data.message)
            setHandleEditBoxPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>
            <Modal
                className='add-box-modal'
                show={handleEditBoxPopup}
                onHide={() => {
                    setHandleEditBoxPopup(false)
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleEditBoxPopup(false)
                        }}
                            className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Edit Box</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                {/* <div className='text-center'>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the look.
                                    </p>
                                </div> */}

                                <div className=''>
                                    <Form.Label>Box Title</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.LESSON_TITLE}
                                            autoComplete="off"
                                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="lessonTitle"
                                            type="text"
                                            autoFocus={true}
                                            placeholder="Enter box title"
                                            {...register("lessonTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.LESSON_TITLE,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.lessonTitle && <small className='text-red'>{errors.lessonTitle.message}</small>}
                                </div>

                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Update</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditBoxPopup