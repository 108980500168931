import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ImagePopupContext, RemoveResourcePopupContext } from '../../App'
import Assets from '../../constants/images'
import { ImageIcon, ImageIconSmall } from '../../constants/svgs'

const ImageCardSmall = ({ data, setImagePopupItem, setSelectedResource }) => {
    const [handleImagePopup, setHandleImagePopup] = useContext(ImagePopupContext);
    const [handleRemoveResourcePopup, setHandleRemoveResourcePopup] = useContext(RemoveResourcePopupContext);
    const [isError, setIsError] = useState(false)
    return (
        <div className="image-card-small border p-2">
            <div className='d-flex '>
                <div className='img-container' onClick={() => {
                    setImagePopupItem({ file_url: data.file_url })
                    setHandleImagePopup(true)
                }}>
                    {
                        !isError ?
                            <img
                                onError={(e) => {
                                    e.target.src = Assets.Men
                                    setIsError(true)
                                }}
                                src={data?.file_url ? data.file_url : Assets.Men}
                                alt="Men"
                            /> :
                            <div className='icon-container px-2'>
                                <ImageIconSmall />
                            </div>
                    }

                </div>

                <div className='w-100 ms-3'>
                    <div className='mb-custom'><ImageIcon /></div>
                    <div className='sub-div d-flex w-100 justify-content-between align-items-center'>
                        <h6
                            className="m-0 p-0"
                            onClick={()=>{
                                setImagePopupItem({ file_url: data.file_url })
                                setHandleImagePopup(true)
                            }}
                        >
                            {data?.title}
                        </h6>

                        <Button onClick={() => {
                            setHandleRemoveResourcePopup(true)
                            setSelectedResource(data)
                        }} className="btn-remove ms-2">Remove</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageCardSmall