import React, {useContext, useState, useEffect} from 'react'
import {Button, Form, InputGroup, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {AddGroupPopupContext, StripeCardPopupContext} from "../../App"
import {Controller, useForm} from "react-hook-form";
import {SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';
import {success, error} from "../../constants/msg";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {AddGroup} from '../../services/auth/auth.services';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {AddStripeCard, GetSubscriptionPackages} from "../../services/auth/auth.services";

const StripeCardPopup = ({setIsCardAdded}) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const {register, control, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const [handleStripeCardPopup, setHandleStripeCardPopup] = useContext(StripeCardPopupContext)
    const [isDisabled, setIsDisabled] = useState(false)

    const onSubmit = async (data) => {
        setIsDisabled(false)
        setHandleStripeCardPopup(false)

        if (elements == null) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        try {
            setIsDisabled(true)
            const res = await addUserCard({stripe_token: paymentMethod.id})
            // const res = await addUserCard({stripe_token: "pm_card_visa"})
            success(res.data.message)
            setIsDisabled(false)
            setHandleStripeCardPopup(false)
            setIsCardAdded(true)
        } catch (e) {
            error(e.response.data.message)
            setTimeout(() => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    const addUserCard = async (data) => {
        try {
            const res = await AddStripeCard(data);
        } catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div>
            <Modal
                className='add-group-modal'
                show={handleStripeCardPopup}
                onHide={() => {
                    setHandleStripeCardPopup(false)
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleStripeCardPopup(false)
                        }}
                             className="fa-times"><FaTimes/></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Add Card</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                <CardElement/>
                                <Button disabled={isDisabled} className="mt-5 w-100" type="submit"
                                        disabled={!stripe || !elements}>
                                    Add Card
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default StripeCardPopup