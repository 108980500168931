import React from 'react'

const NotificationCard = ({ data, bg }) => {
    return (
        <div className='notification-card mb-3'>
            <div className={`holder d-flex align-items-center p-3 ${data?.read_at===null && "active"}`}>
                <div className={`name-container ${bg}`}>
                    <h6 className='p-0 m-0'>{data?.notifiable_type.slice(0,2)}</h6>
                </div>

                <div className='notification-content ms-3'>
                    <p className='p-0 m-0'>
                       {data?.data?.message}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NotificationCard 