import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { PdfPopupContext } from "../../App"
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Assets from '../../constants/images';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const PdfPopup = ({ data }) => {
    const [handlePdfPopup, setHandlePdfPopup] = useContext(PdfPopupContext);
    const docs = [
        { uri: data.file_url },
    ]

    return (
        <div>
            <ToastContainer />
            <Modal
                className='pdf-modal'
                show={handlePdfPopup}
                onHide={() => setHandlePdfPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandlePdfPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='file-viewer'>
                        <DocViewer
                            pluginRenderers={DocViewerRenderers}
                            documents={docs}
                            paginated={true}

                            config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: false,
                                    retainURLParams: false
                                }
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PdfPopup


