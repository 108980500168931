import React from 'react'
import { MusicPlayButton, PlayIcon } from '../../constants/svgs'
import Assets from '../../constants/images'
import { MdDownloadForOffline } from "react-icons/md"
import { Button } from "react-bootstrap"

const VideoCard = ({ data, downloadFile, click, setClick }) => {
    return (
        <div onClick={() => setClick(!click)} className='my-card file-card p-2 pb-3'>
            {/* <Button onClick={()=>downloadFile(data?.file_url)} className='download-btn'><MdDownloadForOffline /></Button> */}
            <div className='bg-div bg-light-grey py-4'>
                <div className='file-icon-container bg-white'>
                    <MusicPlayButton />
                </div>
            </div>
            <span className='d-inline-block mt-2'>{data?.title}</span>
        </div>
    )
}

export default VideoCard