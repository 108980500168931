import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AddGroupPopupContext } from "../../App"
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { useNavigate } from "react-router-dom";

const ForcePasswordPopup = ({ resetPasswordPopup, setResetPasswordPopup }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    return (
        <div>
            <Modal
                className='add-client-popup force-reset-password'
                show={resetPasswordPopup}
                onHide={() => setResetPasswordPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setResetPasswordPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Change Password</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    <span className="text-danger">Warning! </span>Please change your password
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button
                                    className="btn-common me-4 mb-3"
                                    onClick={() => {
                                        setResetPasswordPopup(false)
                                        navigate("/change-password")
                                    }}
                                >
                                    Change Password
                                </Button>
                                
                                <Button onClick={() => setResetPasswordPopup(false)} className="btn-common no-bg me-4">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ForcePasswordPopup