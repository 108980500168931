import React from 'react'
import {Button} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

const SubscriptionPlanCard = ({navigateLink, showBtn, data, permissions, handleScreen}) => {
    const navigate = useNavigate();

    return (
        <div className='subscripion-card p-4'>
            <div className='text-center border-bottom pb-2 mb-4'>
                <h5>{data.name}</h5>
            </div>

            <div>
                <ul>
                    {
                        permissions.map((v, i) => {
                            return (
                                (v.type !== 'text') ?
                                    <li key={i} className={(parseInt(v.value) > 0) ? 'show' : ''}>
                                        {v.name}
                                    </li>
                                    :
                                    <li key={i} className={(v.value !== '') && 'show'}>
                                        {v.name}: {v.value}
                                    </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div>
                {
                    data.is_free &&
                    <div className='text-center border-top pt-4 mt-4 fixed-height'>
                        <h4>{data.name}</h4>
                    </div>
                }

                {
                    !data.is_free &&
                    <div className="fixed-height">
                        <div className='d-flex align-items-center justify-content-between border-top pt-4 mt-4'>
                            <h4>$ {data.monthly_price}</h4>
                            <h4>$ {data.yearly_price}</h4>
                        </div>

                        <div className='d-flex align-items-center justify-content-between'>
                            <small>Per Month</small>
                            <small>Per Year</small>
                        </div>
                    </div>
                }
            </div>
            <div className='text-center mt-5'>
                {showBtn && <Button onClick={() => navigate('/sub-admin/payment-method', {state: {data: data, navigateLink:navigateLink}})}
                                    className='w-100'>Subscribe {data.is_free ? 'Free' : ''}</Button>}
            </div>
        </div>
    )
}

export default SubscriptionPlanCard
