import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { AudioPopupContext, RemoveResourcePopupContext } from '../../App'
import Assets from '../../constants/images'
import { MusicIcon, MusicIconSmall } from '../../constants/svgs'

const AudioCardSmall = ({ data, setAudioPopupItem, setSelectedResource }) => {
    const [handleAudioPopup, setHandleAudioPopup] = useContext(AudioPopupContext);
    const [handleRemoveResourcePopup, setHandleRemoveResourcePopup] = useContext(RemoveResourcePopupContext);

    return (
        <div className="audio-card-small border p-2">
            <div className='d-flex '>
                <div className='img-container'
                    onClick={() => {
                        setAudioPopupItem({ file_url: data.file_url, title: data.title })
                        setHandleAudioPopup(true)
                    }}
                >
                    <div className='icon-container px-2'>
                        <MusicIconSmall />
                    </div>
                </div>

                <div className='w-100 ms-3'>
                    <div className='mb-custom'><MusicIcon /></div>
                    <div className='sub-div d-flex w-100 justify-content-between align-items-center'>
                        <h6
                            className="m-0 p-0"
                            onClick={() => {
                                setAudioPopupItem({ file_url: data.file_url, title: data.title })
                                setHandleAudioPopup(true)
                            }}
                        >
                            {data?.title}
                        </h6>

                        <Button onClick={() => {
                            setHandleRemoveResourcePopup(true)
                            setSelectedResource(data)
                        }} className="btn-remove ms-2">Remove</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AudioCardSmall