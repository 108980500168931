import React, { useEffect, useState, useContext } from 'react'
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Assets from '../../constants/images';
import { CameraIcon } from '../../constants/svgs';
import { UserDataContext } from "../../App"
import { GetCurrentUser, SetAuthUserLocalStorage, UpdateUserProfile } from '../../services/auth/auth.services';
import { UploadFileToS3 } from '../../utils/helpers';
import { constant, s3Credential } from '../../utils/constants';

const EditProfileForm = ({ hidePanel, user }) => {
    const [userData, setUserData] = useContext(UserDataContext)
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);
    const [preview, setPreview] = useState(null);
    const [image, setImage] = useState("");

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const { name, contact, address } = data
        updateUserProfile(name, contact, address, userData.id)
    }

    const handleImageUpload = (e) => {
        let img = e.target.files[0]
        let objectUrl = URL.createObjectURL(img)
        setImage(img)
        setPreview(objectUrl)
    }

    const handleReset = (email, name, phone, address, image_url) => {
        reset({
            email: email,
            name: name,
            contact: phone,
            address: address
        })
        setPreview(image_url)
    }

    const updateUserProfile = async (name, contact, address, id) => {
        setIsDisabled(true)
        if (!image) {
            let isImage = false
            try {
                await UpdateUserProfile(name, contact, address, userData.details.image_url, id, isImage).then((res) => {
                    const data = res.data.data
                    handleReset(data.email, data.name, data.details.phone, data.details.address, data.details.image_url)
                    setUserData(res.data.data)
                    // setPreview(data.details.image_url)
                    SetAuthUserLocalStorage(res.data.data)
                    success(res.data.message)
                    setImage("")
                    setTimeout(() => {
                        setIsDisabled(false)
                    }, 1500);
                })

            }
            catch (e) {
                error(e.response.data.message)
                setTimeout(() => {
                    setIsDisabled(false)
                }, 1500);
            }
        }
        else {
            let isImage = true
            try {
                await UploadFileToS3(image, constant.imageDirName).then(async (document) => {
                    try {
                        await UpdateUserProfile(name, contact, address, document.url, id, isImage).then((res) => {
                            const data = res.data.data
                            handleReset(data.email, data.name, data.details.phone, data.details.address, data.details.image_url)
                            setUserData(res.data.data)
                            SetAuthUserLocalStorage(res.data.data)
                            // setPreview(data.details.image_url)
                            success(res.data.message)
                            setImage("")
                            setTimeout(() => {
                                setIsDisabled(false)
                            }, 1500);
                        })

                    }
                    catch (e) {
                        error(e.response.data.message)
                        setTimeout(() => {
                            setIsDisabled(false)
                        }, 1500);
                    }
                })
            }
            catch (e) {
                error(e.message)
                setTimeout(() => {
                    setIsDisabled(false)
                }, 1500);
            }
        }
    }

    useEffect(() => {
        // const { email, name } = userData
        // const phone = userData?.details?.phone
        // const address = userData?.details?.address
        // const image_url = userData?.details?.image_url
        handleReset(user.email, user.name, user.details.phone, user.details.address, user.details.image_url)
    }, [])

    return (
        <div className="edit-profile-form p-4">
            <h6 className='m-0 p-0'>Edit Profile</h6>
            <ToastContainer />
            <div className="holder mt-4 p-4 p-sm-5">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xs={12} xl={5} className="my-auto text-center">
                            <label htmlFor="img-upload" className='img-upload mb-4 mb-md-0'>
                                <img onError={(e) => e.target.src = Assets.ProfilePlaceHolder} src={preview ? preview : Assets.ProfilePlaceHolder} alt="BrandIcon" />
                                <input
                                    className='d-none'
                                    name="image"
                                    id="img-upload"
                                    type="file"
                                    accept=".png, .jpg"
                                    onChange={(e) => handleImageUpload(e)}
                                />
                                <CameraIcon />
                            </label>
                        </Col>

                        <Col xs={12} xl={7}>
                            <div>
                                <Form.Label>Name</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" && e.preventDefault() || Number(e.key)) && e.preventDefault()}
                                        name="name"
                                        type="text"
                                        placeholder="Enter your name"
                                        maxLength={VALIDATIONS.NAME}
                                        {...register("name",
                                            {
                                                maxLength: {
                                                    value: VALIDATIONS.NAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                },
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                },
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.name && <small className='text-red'>{errors.name.message}</small>}
                            </div>

                            <div className='mt-3'>
                                <Form.Label>Email Address</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        disabled
                                        name="email"
                                        type="email"
                                        placeholder="Enter email address"
                                        maxLength={VALIDATIONS.EMAIL}
                                        {...register("email",
                                            {
                                                maxLength: {
                                                    value: VALIDATIONS.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                },
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                }
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.email && <small className='text-red'>{errors.email.message}</small>}
                            </div>

                            <div className='mt-3'>
                                <Form.Label>Contact Number</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                        name="contact"
                                        type="number"
                                        placeholder="Enter contact number"
                                        maxLength={VALIDATIONS.PHONE_MAX}
                                        {...register("contact",
                                            {
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.PHONE_REQUIRED
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.PHONE_MIN,
                                                    message: VALIDATIONS_TEXT.PHONE_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.PHONE_MAX,
                                                    message: VALIDATIONS_TEXT.PHONE_MAX
                                                },
                                                pattern: {
                                                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                    message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                },
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.contact && <small className='text-red'>{errors.contact.message}</small>}
                            </div>

                            <div className='mt-3'>
                                <Form.Label>Address</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        className="py-3"
                                        name="address"
                                        as="textarea"
                                        placeholder="Enter your address"
                                        maxLength={VALIDATIONS.ADDRESS_MAX}
                                        {...register("address",
                                            {
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.ADDRESS_MAX,
                                                    message: VALIDATIONS_TEXT.ADDRESS_MAX
                                                },
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.address && <small className='text-red'>{errors.address.message}</small>}
                            </div>

                            <div className='mt-5'>
                                <Button disabled={isDisabled} type="submit" className="w-100">Update</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div >
    )
}

export default EditProfileForm