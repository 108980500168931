import React, { useContext, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { ImagePopupContext } from "../../App"
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Assets from '../../constants/images';

const ImagePopup = ({data}) => {
    const [handleImagePopup, setHandleImagePopup] = useContext(ImagePopupContext);

    return (
        <div>
            <ToastContainer />
            <Modal
                className='image-modal'
                show={handleImagePopup}
                onHide={()=>setHandleImagePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleImagePopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div>
                        <img onError={(e)=> e.target.src = Assets.ImagePlaceHolder} className="w-100" src={data.file_url!=="" ? data.file_url : Assets.BobMarley} alt="BobMarley" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ImagePopup