import React, { useContext, useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import { AddClientPopupContext, AddByEmailPopupContext } from "../../App"
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { UploadClientByCsvFile } from '../../services/auth/auth.services'
import { MdOutlineFileDownload } from 'react-icons/md'
import csvTemplate from "../../assets/files/upload-clients-template.xlsx"
import { saveAs } from "file-saver"

const AddClientPopup = ({ count, setCount }) => {
    const navigate = useNavigate()
    const [handleAddClientPopup, setHandleAddClientPopup] = useContext(AddClientPopupContext)
    const [handleAddByEmailPopup, setHandleAddByEmailPopup] = useContext(AddByEmailPopupContext)
    const fileUploadRef = useRef()

    const handleClick = () => {
        setHandleAddClientPopup(false)
        setHandleAddByEmailPopup(true)
    }

    const handleCsvFileUpload = async (e) => {
        let file = e.target.files[0]
        if (file) {
            try {
                const data = new FormData()
                data.append('file', file)
                const res = await UploadClientByCsvFile(data)
                fileUploadRef.current.value = null
                setCount(count + 1)
                setHandleAddClientPopup(false)
                success(res.data.message)
            }
            catch (e) {
                fileUploadRef.current.value = null
                error(e.response.data.errors.email[0])
            }
        }
    }

    const downloadCsvTemplate = () => {
        saveAs("https://grahamity-s3.s3.amazonaws.com/csv-template+(2).xlsx", "csv-template")
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                className='add-client-popup'
                show={handleAddClientPopup}
                onHide={() => setHandleAddClientPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleAddClientPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Add Client</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Select the following options to select the client
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button onClick={() => handleClick()} className="btn-common me-4">Add By Email</Button>
                                <Button className="btn-common no-bg">
                                    <label htmlFor="csvFile">
                                        <input ref={fileUploadRef} onChange={(e) => handleCsvFileUpload(e)} className="d-none" id="csvFile" type="file" accept=".xlsx, .csv" />
                                        Import CSV
                                    </label>
                                </Button>
                            </div>

                            <div className='download-csv mt-4' onClick={() => downloadCsvTemplate()}>
                                <MdOutlineFileDownload className='me-1' />
                                Download CSV file template
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddClientPopup