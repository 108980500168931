import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GetPages } from "../services/auth/pages.services"
import parse from 'html-react-parser';
import { success, error } from "../constants/msg";
import { ToastContainer } from "react-toastify";
import Loader from '../components/loader/Loader';

const PrivacyPolicyPage = ({ noGutters, height }) => {
    const [pageData, setPageData] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const FetchPage = async () => {
            try {
                const res = await GetPages(2);
                setIsLoading(false)
                setPageData(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }
        FetchPage()
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className='mt-4 text-center'>
                        <Loader />
                    </div>
                    :
                    <div className="privacy-policy">
                        <ToastContainer />
                        <Row className={!noGutters && 'mt-4'}>
                            <Col xs={12}>
                                <div className={`div-content p-4 bg-white ${height && "h-100vh"}`}>
                                    <div className='border-bottom pb-4 mb-4'>
                                        <h5 className='p-0 m-0'>{pageData.title}</h5>
                                    </div>
                                    {

                                        pageData.content &&
                                        parse(pageData.content)
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
            }
        </>
    )
}

export default PrivacyPolicyPage