import React, { useState, useContext, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { RemoveGroupPopupContext } from "../../App"
import { useNavigate } from 'react-router-dom'
import { Logout, EmptyLocalStorage, DeleteResource, DeleteGroup } from "../../services/auth/auth.services"
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";

const MoveDeletedBoxPopup = ({ selectedBox, mergeGroupAndBoxes, handleMoveDeletedBoxPopup, setHandleMoveDeletedBoxPopup }) => {
    const [isDisabled, setIsDisabled] = useState(false)

    console.log(mergeGroupAndBoxes)

    return (
        <div>
            <Modal
                className='move-box-modal'
                show={handleMoveDeletedBoxPopup}
                onHide={() => setHandleMoveDeletedBoxPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleMoveDeletedBoxPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Move Box</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Are you sure you want to move the <b>{selectedBox?.name}</b>
                                </p>

                                <div className='mt-4'>
                                    <Form.Select className="py-2" aria-label="Default select example">
                                        {
                                            mergeGroupAndBoxes.map((data, index) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <Button className="btn-common me-4">Move</Button>
                                <Button className="btn-common no-bg">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MoveDeletedBoxPopup