import React, { useContext, useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { RemoveResourcePopupContext } from "../../App"
import { useNavigate } from 'react-router-dom'
import { Logout, EmptyLocalStorage, DeleteResource } from "../../services/auth/auth.services"
import { success, error } from "../../constants/msg";
import {ToastContainer} from "react-toastify";

const RemoveResourcePopup = ({ deletedGroupData, setDeletedGroupData, activeDeletedGroupBox, resourceBoxes, setResourceBoxes, showSelectedResource, setshowSelectedResource, selectedResource }) => {
    const [handleRemoveResourcePopup, setHandleRemoveResourcePopup] = useContext(RemoveResourcePopupContext);
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()

    const returnIndexshowSelectedResource = () => {
        for (let i = 0; i < showSelectedResource.length; i++) {
            if (showSelectedResource[i].resource_boxId == selectedResource.resource_boxId) {
                return i
            }
        }
    }

    const returnIndexResourceBox = () => {
        for (let i = 0; i < resourceBoxes.length; i++) {
            if (resourceBoxes[i].resource.resource_boxId == selectedResource.resource_boxId) {
                return i
            }
        }
    }

    const returnIndexDeletedGroupBox = () => {
        for (let i = 0; i < deletedGroupData.length; i++) {
            for (let j = 0; j < deletedGroupData[i].resources.length; j++) {
                if (deletedGroupData[i].resources[j].resource_boxId == selectedResource.resource_boxId) {
                    return { i, j }
                }
            }
        }
    }

    const deleteResource = async () => {
        setIsDisabled(true)
        try {
            await DeleteResource(selectedResource.resource_boxId).then((res) => {
                if (activeDeletedGroupBox) {
                    let temp_1 = [...showSelectedResource]
                    let temp_2 = [...deletedGroupData]

                    let index_1 = returnIndexshowSelectedResource()
                    let { i, j } = returnIndexDeletedGroupBox()
                    console.log(i,j)
                    temp_1.splice(index_1, 1)
                    temp_2[i].resources.splice(j, 1)

                    setshowSelectedResource(temp_1)
                    setDeletedGroupData(temp_2)

                    success(res.data.message)
                    setHandleRemoveResourcePopup(false)
                    setIsDisabled(false)
                }
                else {
                    let temp_1 = [...showSelectedResource]
                    let temp_2 = [...resourceBoxes]
                    let index_1 = returnIndexshowSelectedResource()
                    let index_2 = returnIndexResourceBox()

                    temp_1.splice(index_1, 1)
                    temp_2.splice(index_2, 1)

                    setshowSelectedResource(temp_1)
                    setResourceBoxes(temp_2)

                    success(res.data.message)
                    setHandleRemoveResourcePopup(false)
                    setIsDisabled(false)
                }
            })
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                className='logout-modal'
                show={handleRemoveResourcePopup}
                onHide={() => setHandleRemoveResourcePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleRemoveResourcePopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Resource</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Are you sure you want to delete resource ?
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button disabled={isDisabled} onClick={() => deleteResource()} className="btn-common me-4">Delete</Button>
                                <Button onClick={() => setHandleRemoveResourcePopup(false)} className="btn-common no-bg">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RemoveResourcePopup