import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { FileIcon } from '../../constants/svgs'
import { NotesPopupContext } from "../../App"
import NotesPopup from '../popup/NotesPopup'
import Assets from '../../constants/images'

const CurrentSessionCard = ({ index, data }) => {
  const [handleNotesPopup, setHandleNotesPopup] = useContext(NotesPopupContext);
  const images = [Assets.Art1, Assets.Art2]

  const returnImage = () => {
    if (index % 2 == 0) {
      return images[0]
    }
    else {
      return images[1]
    }
  }

  return (
    <div className='current-session-card text-center p-2'>
      {/* <div className='file-icon-container' onClick={() => setHandleNotesPopup(true)}>
        <FileIcon />
      </div> */}
      <NavLink to={`/current-session/${data?.box?.id}`}>
        <img src={returnImage()} alt="image" />
        <h6 className='p-0 m-0 mt-2'>{data?.box?.name}</h6>
      </NavLink>
    </div>
  )
}

export default CurrentSessionCard