import React, { useContext, useEffect, useState } from 'react'
import CurrentSessionCard from '../components/home/CurrentSessionCard'
import { Col, Container, Row } from 'react-bootstrap'
import Assets from '../constants/images'
import NotesPopup from '../components/popup/NotesPopup'
import { GetEssionsListing, GetGroups, GetResourceBox, GetResourceClient } from '../services/auth/auth.services'
import { UserDataContext } from "../App"
import { error } from '../constants/msg'
import Loader from '../components/loader/Loader'
import ForcePasswordPopup from '../components/popup/ForcePasswordPopup'

const CurrentSessionPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useContext(UserDataContext);
  const [boxes, setBoxes] = useState([]);
  const [resetPasswordPopup, setResetPasswordPopup] = useState(false)

  useEffect(() => {
    const getResourceClientAndBoxes = async () => {
      try {
        const res = await GetResourceClient(userData.subadmin_user_id)
        let data = res.data.data

        // remove duplicate boxes from data
        data = data.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.box_id == value.box_id && t.reciever_id == value.reciever_id
          ))
        )

        setBoxes(data)
        setIsLoading(false)
      }
      catch (e) {
        error("")
        setIsLoading(false)
      }
    }

    getResourceClientAndBoxes()

    if (userData.change_password === 0) {
      setResetPasswordPopup(true)
    }
  }, [userData])


  return (
    <div className='current-session-page'>
      {/* <NotesPopup /> */}
      {
        isLoading ?
          <div className='text-center'>
            <Loader />
          </div>
          :
          <Row>
            <ForcePasswordPopup resetPasswordPopup={resetPasswordPopup} setResetPasswordPopup={setResetPasswordPopup} />
            
            <Col xs={12} sm={10}>
              <Row>
                {
                  boxes.length > 0 ?
                    boxes.map((v, i) => {
                      return (
                        <Col key={i} xl={3} xs={12} sm={6} md={6} lg={4} className="mb-4">
                          <CurrentSessionCard index={i} data={v} />
                        </Col>
                      )
                    })
                    :
                    <h3 className='text-muted'>No box is available</h3>
                }
              </Row>
            </Col>
          </Row>

      }
    </div>
  )
}

export default CurrentSessionPage