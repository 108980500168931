import React from 'react'
import Assets from '../../constants/images'

const ImageViewer = ({ data }) => {
  return (
    <div className='image-viewer'>
      <div className="div-img mb-4">
        <img onError={(e)=> e.target.src = Assets.ImagePlaceHolder} className='w-100' src={data?.file_url} alt="image" />
      </div>
      {/* <h6 className='p-0 m-0'>Image Details</h6> */}
    </div>
  )
}

export default ImageViewer